import React, { useState, useEffect, useRef } from 'react'
import BlogContent from './BlogContent'

export default function HorizontalTabs(props) {
  const tabsRef = useRef(null)
  const [selected, setSelected] = useState(0)

  useEffect(() => {
    const setSelectedTab = () => {
      const fragment = window.location.hash?.substring(1)
      if (fragment) {
        const selectedTab = props.tabs?.indexOf(props.tabs?.find((x) => x.fragment === fragment))
        if (selectedTab !== -1) {
          setSelected(selectedTab)

          setTimeout(() => {
            const position = tabsRef.current.getBoundingClientRect().top
            const offsetPosition = position + window.scrollY - 136
            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth'
            })
          }, 700)
        }
      }
    }

    setSelectedTab()
    window.addEventListener('hashchange', setSelectedTab, false)

    return () => window.removeEventListener('hashchange', setSelectedTab, false)
  }, [])

  return (
    <div
      ref={tabsRef}
      className={`container mx-auto ${props.noPaddingTop ? 'pb-10' : 'py-10'} px-6`}
    >
      {props.tabs && props.tabs.length > 1 && (
        <div className="w-full flex justify-start overflow-x-auto">
          <div className="flex-1 flex-nowrap inline-flex border border-black bg-white rounded-lg">
            {props.tabs &&
              props.tabs.map((tab, i) => {
                return (
                  <button
                    key={`tabs-image${i}`}
                    onClick={() => setSelected(i)}
                    className={`flex-1 tabs-border-dark text-black relative whitespace-nowrap px-4 lg:px-5 py-2 md:py-3 text-xs md:text-sm font-semibold tracking-wider text-center uppercase rounded-lg ${
                      selected === i ? 'is-active bg-gray-300 ' : ''
                    }`}
                  >
                    {tab.tabTitle || tab.title}
                  </button>
                )
              })}
          </div>
        </div>
      )}

      <div className="w-full mt-12">
        {props.tabs &&
          props.tabs.map((item, i) => {
            return (
              <div key={`tab-content-${i}`} className={`${selected === i ? 'block' : 'hidden'}`}>
                <BlogContent key={`content-${i}`} {...item} />
              </div>
            )
          })}
      </div>
    </div>
  )
}
