import React from 'react'
import SidebarBlock from './SidebarBlock'
import QuoteBlock from './QuoteBlock'
import { NewsCard } from '@truphone/core-components'
import { LinkWrap } from '@truphone/gatsby-wrappers'

export default function SidebarContent(props) {
  if (!props.content) {
    return <></>
  }

  return (
    <div className="mt-10 lg:mt-0 lg:pl-6">
      {props.content.map((item, i) => {
        if (item.type === 'callout') {
          return (
            <SidebarBlock
              key={`sidebar-${i}`}
              backgroundColor={item.backgroundColor}
              title={item.title}
              content={item.content}
              cta={item.cta}
            />
          )
        }
        if (item.type === 'quote') {
          return (
            <QuoteBlock
              key={`sidebar-${i}`}
              textSmall
              marginTop
              text={item.text}
              quoteAuthor={item.quoteAuthor}
              quoteAuthorTitle={item.quoteAuthorTitle}
            />
          )
        }

        if (item.type === 'article' && item.article) {
          return (
            <LinkWrap key={`sidebar-${i}`} className="block mb-8" href={item.article.link}>
              <NewsCard {...item.article} marginBottom />
            </LinkWrap>
          )
        }

        return <></>
      })}
    </div>
  )
}
