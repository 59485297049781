import { ImageWrap, LinkWrap } from '@truphone/gatsby-wrappers'
import React from 'react'
import '../../style.css'

export default function HoverBoxes(props) {
  return (
    <div className="container mx-auto px-6">
      {props.boxes && (
        <div className="flex flex-wrap justify-center -mx-4 py-4">
          {props.boxes.map((box, i) => {
            return (
              box.cta && (
                <LinkWrap
                  href={box.cta.href}
                  key={`hover-box-${i}`}
                  className="relative m-4 hover-box image-hover w-44 h-44 overflow-hidden rounded-lg text-center flex items-end"
                >
                  {box.image && <ImageWrap className="absolute w-full h-full" {...box.image} />}
                  <div className="p-4 relative z-1">
                    {box.title && (
                      <h2 className="text-base tracking-wider font-bold uppercase leading-tight text-white">
                        {box.title}
                      </h2>
                    )}

                    <span className="block p-3 text-white font-semibold bg-white bg-opacity-20 rounded-lg mt-4">
                      {box.cta.text}
                    </span>
                  </div>
                </LinkWrap>
              )
            )
          })}
        </div>
      )}
    </div>
  )
}
