import { ImageWrap } from '@truphone/gatsby-wrappers'
import React from 'react'

export default function QuoteBlock(props) {
  return (
    <blockquote
      className={`${
        props.textLarge
          ? 'text-center text-2xl font-light max-w-5xl mx-auto px-3 py-16'
          : props.textSmall
          ? 'text-sm'
          : 'text-base'
      } ${!props.textLarge ? 'border-l-2 pl-5 pr-6 border-grey-200 mb-8' : ''} ${
        props.marginTop ? 'mt-8' : ''
      }`}
    >
      <p className="italic mb-4">{props.text}</p>
      <div className={`${props.image ? 'flex justify-center items-center mt-8' : ''}`}>
        {props.image && (
          <div
            className="rounded-2xl overflow-hidden mr-4"
            style={{
              boxShadow: '0 1px 3px 0 rgb(1 49 63 / 30%), 2px 2px 10px 0 rgb(1 49 63 / 30%)'
            }}
          >
            <ImageWrap className="" {...props.image} />
          </div>
        )}
        {props.quoteAuthor && (
          <p className={`${props.image ? 'flex flex-col text-left' : 'mt-10'} text-base`}>
            <b
              className={`${
                props.image ? 'font-semibold uppercase tracking-wider' : 'font-semibold'
              }`}
            >
              {props.quoteAuthor}
            </b>
            {props.image || props.textLarge ? '' : ', '}
            <span className={`${props.image ? 'text-sm' : props.textLarge ? 'block font-medium text-gray-700' : ''}`}>
              {props.quoteAuthorTitle}
            </span>
          </p>
        )}
      </div>
    </blockquote>
  )
}
