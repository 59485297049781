import { LinkWrap } from '@truphone/gatsby-wrappers'
import React, { useState } from 'react'
import '../../style.css'

export default function SplitBackground(props) {
  const [selectedItem, setSelectedItem] = useState(
    props.icons && props.icons[0] ? props.icons[0].text : null
  )
  const handleSelected = (e, i) => {
    let scrollLeft = e.target.getBoundingClientRect().x - 25
    e.target.parentElement.scrollBy(scrollLeft, 0)
    setSelectedItem(props.icons[i] ? props.icons[i].text : null)
  }
  return (
    <div className="bg-white pt-8 pb-12 overflow-hidden">
      <div className="container mx-auto px-6">
        {props.imageRight ? (
          <div className="flex flex-col lg:flex-row items-start">
            <div
              className={`relative lg:w-7/12 pt-8 pb-4 px-4 bg-split-right ${
                props.gradient ? 'text-white bg-split bg-gradient-split' : 'bg-split bg-color-grey'
              }`}
            >
              <div className="relative z-1">
                <div className="overline-bold uppercase mb-4">
                  <p className={`${props.gradient ? '' : 'text-grey-700'}`}>{props.number}</p>
                  {props.titleLeft}
                </div>
                <h2 className="text-2xl font-light">{props.title}</h2>
                <p className="text-body mt-8">{props.subtitle}</p>
                {props.content && (
                  <div
                    className="text-base list-disc list-item-spacing p-margin"
                    dangerouslySetInnerHTML={{ __html: props.content }}
                  />
                )}
              </div>
            </div>
            <div className="lg:w-5/12 relative z-1 mt-8">
              {props.image && (
                <div className={`mb-8 lg:mb-0 lg:-mr-36 lg:pl-14`}>
                  <img className="rounded-lg" src={props.image.url} alt={props.image.alt} />
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="w-full flex flex-col lg:flex-row items-start ">
            <div className="shrink-0 lg:w-1/6 pt-4 pr-8 pb-8 pl-0 overline-bold uppercase">
              <p className="text-grey-700">{props.number}</p>
              {props.titleLeft}
            </div>
            <div
              className={`relative w-full lg:w-5/6 py-8 ${
                props.gradient ? 'text-white bg-split bg-gradient-split' : ' bg-split bg-color-grey'
              }`}
            >
              <div className="relative z-1">
                <h2 className="text-2xl font-light">{props.title}</h2>
                <p className="text-body mt-8">{props.subtitle}</p>
              </div>

              {props.icons ? (
                <div className="relative z-1 mt-8 lg:pl-2">
                  <div className="sm:hidden relative w-full overflow-hidden">
                    <div className="styled-scroll flex flex-nowrap pb-4">
                      {props.icons.map((icon, i) => {
                        return (
                          <div
                            className="flex shrink-0 cursor-pointer"
                            key={`mobile-icon-${i}`}
                            href={icon.href}
                            onClick={(e) => handleSelected(e, i)}
                          >
                            <div className="pointer-events-none shrink-0 relative block rounded-2xl bg-white p-3 mr-4 my-auto">
                              <img className="" src={icon.image.url} alt={icon.image.alt} />
                            </div>
                            <div className="pointer-events-none shrink-0 block pr-8">
                              {icon.title && (
                                <div className="text-base font-semibold"> {icon.title}</div>
                              )}
                              {icon.subtitle && <p className="text-xs mt-2">{icon.subtitle}</p>}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    <div
                      className="text-base mt-6"
                      dangerouslySetInnerHTML={{ __html: selectedItem }}
                    />
                  </div>

                  <div className="hidden sm:block">
                    {props.icons.map((icon, i) => {
                      return (
                        <div key={`icon-${i}`} className="w-full flex mb-6">
                          <div
                            className={`${
                              i < props.icons.length - 1 ? 'dashed-line' : ''
                            } relative lg:w-1/4 flex pr-6 shrink-0`}
                          >
                            {icon.image && (
                              <>
                                {icon.href ? (
                                  <LinkWrap
                                    href={icon.href}
                                    className="relative block rounded-2xl bg-white p-3 lg:mr-4 mb-auto shrink-0"
                                  >
                                    <img className="" src={icon.image.url} alt={icon.image.alt} />
                                  </LinkWrap>
                                ) : (
                                  <div className="relative block rounded-2xl bg-white p-3 lg:mr-4 mb-auto shrink-0">
                                    <img className="" src={icon.image.url} alt={icon.image.alt} />
                                  </div>
                                )}
                              </>
                            )}
                            <div
                              className="hidden lg:block break-words"
                              style={{ width: 'calc(100% - 64px)' }}
                            >
                              {icon.title && (
                                <>
                                  {icon.href ? (
                                    <LinkWrap
                                      href={icon.href}
                                      className="hover-blue-line text-base font-semibold"
                                    >
                                      {icon.title}
                                    </LinkWrap>
                                  ) : (
                                    <div className="text-base font-semibold"> {icon.title}</div>
                                  )}
                                </>
                              )}
                              {icon.subtitle && <p className="text-xs">{icon.subtitle}</p>}
                            </div>
                          </div>
                          <div className="w-3/4">
                            <div className="mb-4 block lg:hidden">
                              {icon.title && (
                                <>
                                  {icon.href ? (
                                    <LinkWrap
                                      href={icon.href}
                                      className="hover-blue-line text-base font-semibold"
                                    >
                                      {icon.title}
                                    </LinkWrap>
                                  ) : (
                                    <div className="text-base font-semibold"> {icon.title}</div>
                                  )}
                                </>
                              )}
                              {icon.subtitle && <p className="text-xs">{icon.subtitle}</p>}
                            </div>
                            {icon.text && (
                              <div
                                className="text-base list-disc list-item-spacing p-margin link-underline"
                                dangerouslySetInnerHTML={{ __html: icon.text }}
                              />
                            )}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              ) : (
                <div className="relative z-1 flex flex-col md:flex-row mt-8 items-start">
                  <div className="md:w-5/12">
                    {props.image && (
                      <div className={`mb-8 lg:mb-0 lg:-ml-36 pr-6 lg:pr-14`}>
                        <img className="rounded-lg" src={props.image.url} alt={props.image.alt} />
                      </div>
                    )}
                  </div>
                  <div className="md:w-7/12">
                    {props.content && (
                      <div
                        className="text-base list-disc list-item-spacing p-margin link-underline"
                        dangerouslySetInnerHTML={{ __html: props.content }}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
