import { VideoModal } from '@truphone/modals'
import React, { useState } from 'react'
import loadable from '@loadable/component'

const ReactPlayer = loadable(() => import('react-player'))

const VideoImage = (props) => {
  return (
    <div className="relative cursor-pointer w-full h-full">
      <div className="image-hover w-full h-full rounded-sm max-w-none">
        <img
          className="absolute w-full h-full max-w-none object-cover block"
          src={props.image.url}
          alt={props.image.alt}
        />
      </div>
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <img width="80" height="80" src="/images/icons/icon-play.svg" alt="play" />
      </div>
      <p
        className={`${
          props.showText ? 'block' : 'hidden'
        } text-white font-bold absolute bottom-5 left-1/2 -translate-x-1/2 uppercase text-base`}
      >
        {props.playText}
      </p>
    </div>
  )
}

export default function Video(props) {
  const [isPlaying, setPlaying] = useState(props.isPlaying || false)
  const [videoHeight, setVideoHeight] = useState(325)

  const removeHeight = () => {
    setVideoHeight('auto')
  }

  const playVideo = (size) => {
    if (size === 'large') {
      removeHeight()
    }
    if (props.playVideo) {
      props.playVideo()
    } else {
      setPlaying(true)
    }
  }
  return (
    <>
      {props.large ? (
        <div
          className="relative h-full rounded-lg overflow-hidden shadow-sm mt-4 mb-5"
          onClick={() => playVideo('large')}
          style={{ height: videoHeight }}
        >
          {props.isPlaying || isPlaying ? (
            <div className={`relative h-0 img-wrap`} style={{ paddingBottom: '56.25%' }}>
              <ReactPlayer
                className="absolute top-0 left-0 w-full h-full"
                controls
                playing={props.isPlaying || isPlaying}
                height="100%"
                width="100%"
                url={props.videoUrl}
              />
            </div>
          ) : (
            <VideoImage {...props} />
          )}
        </div>
      ) : (
        <VideoModal href={props.videoUrl} isVideo>
          <div
            className="relative h-full rounded-lg overflow-hidden shadow-sm mt-4 mb-5"
            // onClick={() => handleOpen()}
            style={{ height: videoHeight }}
          >
            <VideoImage {...props} />
          </div>
        </VideoModal>
      )}
    </>
  )
}
