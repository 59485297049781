import React, { useState } from 'react'
import { Card, CardAccordion, IconCard, ArrowSimple } from '@truphone/core-components'

const CardContent = (props) => {
  return (
    <>
      <div className={`hidden lg:block w-full ${props.halfWidth ? 'lg:w-1/2' : 'lg:w-1/3'} p-5`}>
        {props.iconsCard ? (
          <IconCard {...props.card} borderLeft={props.borderLeft} />
        ) : (
          <Card {...props.card} gradient={props.gradient} />
        )}
      </div>
      <div className="block lg:hidden w-full px-4 py-3 bg-white shadow-sm rounded-lg mb-2 ">
        <h2
          className="text-xl md:text-2xl font-light cursor-pointer flex justify-between items-center"
          onClick={() => props.setSelected && props.setSelected()}
        >
          {props.iconsCard ? props.card.topTitle : props.card.title}
          <span className={`pr-1 opacity-50`}>
            <ArrowSimple className={`w-4 h-4  ${props.selected ? 'rotate-180' : ''}`}></ArrowSimple>
          </span>
        </h2>
        <CardAccordion selected={props.selected} {...props.card} />
      </div>
    </>
  )
}
export default function FinanceCardContainer(props) {
  const [selected, setSelected] = useState(-1)
  return (
    <div className={`${props.backgroundImage ? 'bg-cover' : ''}  py-10`}>
      <div className="container mx-auto pt-4 px-4 lg:px-6">
        <div
          className={`flex flex-wrap ${props.leftAlignedCards ? '' : 'justify-end'} pb-8 lg:pb-16`}
        >
          <div
            className={`w-full ${
              props.leftAlignedCards ? 'lg:w-1/4 xl:w-1/6' : 'lg:w-1/3'
            } pt-4 pb-8 lg:pr-5`}
          >
            <div className="overline-bold uppercase">
              <p className="text-grey-700">{props.number}</p>
              <p>{props.titleLeft}</p>
            </div>
            {props.content && !props.leftAlignedCards && (
              <p className="text-base mt-4">{props.content}</p>
            )}
          </div>
          {props.cards && props.leftAlignedCards ? (
            <div className="lg:3/4 xl:w-5/6 lg:p-5 pt-0">
              {props.title && <p className="text-2xl xl:mt-4">{props.title}</p>}
              {props.content && <p className="text-base mt-4 mb-8 lg:mb-4">{props.content}</p>}
              <div className="flex flex-wrap lg:-mx-5">
                {props.cards.map((card, i) => {
                  return (
                    <CardContent
                      halfWidth={props.leftAlignedCards}
                      setSelected={() => {
                        selected === i ? setSelected(null) : setSelected(i)
                      }}
                      key={`side-card-${i}`}
                      {...props}
                      card={card}
                      selected={selected === i}
                    />
                  )
                })}
              </div>
            </div>
          ) : (
            <>
              {props.cards.map((card, i) => {
                return (
                  <CardContent
                    setSelected={() => {
                      selected === i ? setSelected(null) : setSelected(i)
                    }}
                    key={`side-card-${i}`}
                    {...props}
                    card={card}
                    selected={selected === i}
                  />
                )
              })}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
