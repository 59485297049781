import React from 'react'
import BodyContent from './BodyContent'
import SidebarContent from './SidebarContent'

export default function BlogContent(props) {
  return (
    <div className="container mx-auto">
      <div className="flex flex-wrap w-full justify-between">
        <div
          className={`w-full lg:w-2/3 max-w-full lg:pr-4 ${
            props.person ? 'flex flex-col lg:flex-row' : ''
          }`}
        >
          {props.person && (
            <div className="w-full lg:w-1/3 xl:w-1/4 pb-8 pr-4">
              {props.person.image && (
                <div className="relative w-44 h-40 rounded-lg overflow-hidden shadow-sm mb-4">
                  <img
                    className="absolute w-full h-full max-w-none object-cover"
                    src={props.person.image.url}
                    alt={props.person.image.alt}
                  />
                </div>
              )}
              {props.person.name && <p className="text-base font-bold">{props.person.name}</p>}
              {props.person.jobTitleAndCompany && (
                <p className="text-sm ">{props.person.jobTitleAndCompany}</p>
              )}
            </div>
          )}
          <div className={`${props.person ? 'w-full lg:w-2/3 xl:w-3/4' : ''}`}>
            <BodyContent content={props.content} />
          </div>
        </div>
        <div className="w-full lg:w-1/3">
          <SidebarContent content={props.sidebar} />
        </div>
      </div>
    </div>
  )
}
