import React from 'react'

export default function TitleSection(props) {
  return (
    <div
      className={`container mx-auto px-6 ${
        props.smallPadding ? 'py-4' : props.noPaddingBottom ? 'pt-16' : 'py-16'
      }  flex flex-col lg:flex-row`}
    >
      <div className="overline-bold uppercase w-full lg:w-1/4 xl:w-1/6 py-2 px-4 lg:py-4 pl-0">
        <p className="text-grey-700">{props.number}</p>
        {props.titleLeft}
      </div>
      <h2 className="text-2xl w-full lg:w-3/4 xl:w-5/6 p-4 pl-0 font-light">{props.title}</h2>
    </div>
  )
}
