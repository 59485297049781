import { ArrowSimple } from '@truphone/core-components'
import React from 'react'
const SidebarContent = (props) => {
  return (
    <div
      className="block text-white rounded-lg p-8 pb-6 mb-4"
      style={{ backgroundColor: props.backgroundColor ? props.backgroundColor : '#000e35' }}
    >
      <h4 className="overline-text uppercase font-bold mb-2">{props.title}</h4>
      <div
        className="text-base p-margin list-disc list-margin"
        dangerouslySetInnerHTML={{ __html: props.content }}
      />
      {props.cta && (
        <a
          className="text-xl inline-flex transition-all rounded-lg mt-4 py-2 hover:px-4 items-center opacity-60 hover:opacity-100 hover:bg-white hover:bg-opacity-20"
          href={props.cta.href}
          target={props.cta.target}
        >
          {props.cta.text}
          <ArrowSimple className="ml-2 w-4 h-4 -rotate-90" />
        </a>
      )}
    </div>
  )
}

export default function SidebarBlock(props) {
  return props.cta ? (
    <a href={props.cta.href} className="block hover:shadow-sm hover:text-white">
      <SidebarContent {...props} />
    </a>
  ) : (
    <SidebarContent {...props} />
  )
}
