import React from 'react'
export default function PartnersProfiles(props) {
  return (
    <div className="container mx-auto py-10 px-6 overflow-hidden">
      {props.title && <h2 className="text-3xl text-center font-semibold mb-8">{props.title}</h2>}
      {props.profiles && (
        <div className="flex flex-wrap sm:-mx-3 lg:-mx-6">
          {props.profiles.length > 0 &&
            props.profiles.map((profile, i) => {
              return (
                <div
                  key={`profile-${i}`}
                  className={`${
                    i !== 0 ? 'mt-10 md:mt-0' : ''
                  } w-full md:w-1/2 px-3 lg:px-6 md:grid grid-rows-2`}
                >
                  <div className="flex flex-col lg:flex-row">
                    {profile.image && (
                      <div className="w-52 shrink-0 pr-8">
                        <img src={profile.image.url} alt={profile.image.alt} />
                      </div>
                    )}
                    {profile.title && <h3 className="text-base">{profile.title}</h3>}
                  </div>
                  {profile.content && (
                    <p className="text-sm mt-4 text-grey-700">{profile.content}</p>
                  )}
                </div>
              )
            })}
        </div>
      )}
    </div>
  )
}
