import React from 'react'
import QuoteBlock from './QuoteBlock'
import Video from './Video'

export default function BodyContent(props) {
  return (
    <>
      {props.content &&
        props.content.length > 0 &&
        props.content.map((item, i) => {
          if (item.type === 'content') {
            return (
              <>
                {item.title && <h2 className="text-2xl font-light mb-2">{item.title}</h2>}
                <div
                  key={`content-${i}`}
                  className={'text-base p-margin mb-4 link-blue'}
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
              </>
            )
          }

          if (item.type === 'quote') {
            return <QuoteBlock key={`content-${i}`} {...item} />
          }

          if (item.type === 'video') {
            return (
              <Video
                key={`content-${i}`}
                large={item.large}
                image={item.image}
                imageAlt={item.imageAlt}
                videoUrl={item.url}
                showText={item.showText}
                playText={item.playText}
              />
            )
          }

          // if (item.type === 'faqList') {
          //   return (
          //     <Box  key={`sidebar-content${i}`} mt={6}>
          //       <FAQList
          //         title={item.title}
          //         list={item.faqs}
          //         feedbackEnabled={item.feedbackEnabled}
          //         question1={item.wasThisQuestionHelpful}
          //         buttonTextYes={item.yesText}
          //         buttonTextNo={item.noText}
          //         question2={item.questionOfYourOwn}
          //         contactCta={item.contactCta}
          //         bgColor="transparent"
          //       />
          //     </Box>
          //   )
          // }

          // if (item.type === 'banner') {
          //   return (
          //     <Banner2
          //     key={`sidebar-content${i}`}
          //       alignLeft
          //       topTitle={item.topTitle}
          //       title={item.title}
          //       content={item.content}
          //       backgroundImage={item.backgroundImage}
          //       image={item.image}
          //       cta={item.cta}
          //     />
          //   )
          // }
        })}
    </>
  )
}
